/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Fragment, useState } from 'react'
import { Grid, Typography, Chip, Button, Paper } from "@material-ui/core";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import FilterDrawer from './filterDrawer';
import { Translate } from '../../utils/Translate';
import { useFilter } from "../../hooks/useFilter";
import IconFilter from "../../CommonImages/iconFilter.js"
import IconFilterBlack from "../../CommonImages/IconFilterBlack.js"
import { subCategoryFilterType } from 'utils/constants';

const Filter = (props) => {
  const { useFilterState, useFiltertDispatch } = useFilter();
  const { selectedFilters, filteredKeywords, filterSearchText, loading } = useFilterState();
  const { setKeywords } = useFiltertDispatch();

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const keywordSelected = (keyword) => {
    props.fetchDataByTopKeywords(keyword,undefined,true)
    window.scrollTo(0, 0);
  }

  const checkForSubCategory = () =>{
    if (selectedFilters && selectedFilters.length === 1){
      return selectedFilters[0].filterType !== subCategoryFilterType ?  true : false
    }
  }

  const filterImage = () => {
    if (checkForSubCategory() || selectedFilters?.length > 1 || filterSearchText?.length > 0 ){
      return <IconFilter />
    }
    else{
      return <IconFilterBlack />
    } 
  }

  return (
    !loading && <Fragment>
        <Grid item lg={12} md={12} sm={12} xs={12} className="align-left height-fix m-t-5">
        {props.topKeywords && props.topKeywords?.length > 0 && <Typography className=" text-gray font-h6 topkeyword" variant="caption" >
            {Translate({ id: "ClassListViews.TopKeywords" })}
          </Typography>}
        </Grid>
      <Grid item lg={9} md={9} sm={10} xs={10} className="mobileScroll">
        {props.topKeywords && props.topKeywords?.length > 0 &&
          <Paper component="ul" className="chipUl flexNoWrap">
            {props.topKeywords.map((key, index) => (
              index < 3 && <li key={index}>
                <Chip label={Translate({ id: `keywords.${key}` })} className={`makeTextClickable ${key === filteredKeywords ? "bg-green text-white" : ""}`} onClick={() => { keywordSelected(key) }} role='button' aria-label='keyword' tabIndex='0'>
                </Chip>
              </li>
            ))}
          </Paper>}
      </Grid>
      <Grid item lg={3} md={3} sm={2} xs={2} className="text-right" >
        {(checkForSubCategory() || selectedFilters?.length > 1 || filterSearchText?.length > 0) && <a className="dynamiclinks linkFontSize makeTextClickable align-middle hidden-xs hidden-md" onClick={() => {
          setKeywords('');
          props.fetchDataByFilters([], false, true)
        }} role='button' aria-label='Reset' tabIndex='0'>
          {Translate({ id: "filter.Reset" })}
        </a>}
        <Button variant="contained"
          className={`${(checkForSubCategory() || selectedFilters?.length > 1 || filterSearchText?.length > 0) ? "view-btn filter-btn m-l-16 btn-default" : "view-btn filter-btn m-l-16"}`}
          color={`${(checkForSubCategory() || selectedFilters?.length > 1 || filterSearchText?.length > 0) ? "secondary" : "default"}`}
           onClick={handleDrawerOpen}
           role='button' tabIndex='0' aria-label='Filter'
        >
          <Typography variant="h2" className="hidden-xs MuiTypography-h4">
            {Translate({ id: "ClassListViews.Filter" })}
              {/* <img src={`${selectedFilters?.length > 0 || filterSearchText?.length > 0 ? require("../../assets/images/icon-filter.svg") : require("../../assets/images/icon-filter-black.svg")}`} alt='filter' /> */}
          </Typography>
          {filterImage()}
        </Button>
        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
        >
          <FilterDrawer closeHandler={handleDrawerClose}
            openHandler={handleDrawerOpen}
            keywords={props.topKeywords}
            selected={keywordSelected}
            initialFilterMetaData={props.initialFilterMetaData}
            fetchDataByFilters={props.fetchDataByFilters}
            classesCount={props.classesCount}
            categoryName={props.categoryName}
            fetchDataByFilterSearch={props.fetchDataByFilterSearch}
            subCategory={props.subCategory}
          />
        </SwipeableDrawer>
      </Grid>
    </Fragment>
  )
}
export default Filter;

