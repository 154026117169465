import React, {  Component } from "react";
import "./Workout.css";
import withStyles from "@material-ui/core/styles/withStyles";
import { Translate } from "utils/Translate"
import logger from "utils/logger"
import { getCardsDate } from "utils/LivestreamTime"
import NewButton from "./NewButton";
import { differenceInCalendarDays, parse } from 'date-fns'
import { ClassMediaType, isMobileOrTab,SkillLevelText,TenantLockedContentBehaviour } from "../../../utils/constants";
import moment from "moment";
import {getPosterSize, appendQueryParameterInURL} from "utils/utilityfunctions"
import { LockIcon } from "CommonImages/lock-icon";
import Routes from "components/Router/Routes";
import CustomDialog from "components/Modal/Modal";
import { Button, Typography } from "@material-ui/core";
import { useNavigate } from "react-router";
var config = require("assets/config.json");
const style = {
  pausedButton: {
    width: ".8em",
    height: ".8em",
    marginTop: "-1px",
  },

  newButton: {
    maxWidth: "30px !important",
    minWidth: "29px",
    paddingLeft: "0px",
    paddingRight: "0px",
    fontSize: "8px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "2",
    "& span": {
      paddingLeft: "4px",
      justifyContent: "flex-end",
    },
  },
};

class WorkoutComponent extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("token");
    this.isLocked = this.props.isLocked
    // this.pauseVideo = this.pauseVideo;
    // this.startVideo = this.startVideo;
    // this.player;
    this.myRef = React.createRef();
    this.playerInit = null;
    this.state = {
      isClassFav: this.props.isFav || false,
      selectedItem: "",
      isPlaying: false,
      isClassNew: false,
      isPlayerAvailable: false,
      prevPlayer: null,
      showVideo:false,
      showDialog:false
    };
  }

  componentWillUnmount() {
    if (this.player) {
      this.player=undefined;
    }
    if(this.myRef&&this.myRef.current){
      this.myRef.current.src=""
    }
  }

  componentDidMount() {
    this.isNewCardFn(this.props.displayDate);
  }

  handelRightClick = (player)=>{
    const videoEle = player.getContainer();
    videoEle.addEventListener("contextmenu",()=>{
      try{
        const menuEle = videoEle.querySelectorAll("ul.jw-rightclick-list.jw-reset")[0];
        menuEle.style.display="none";
        return false;
      }catch(e){
        return false;
      }
    });
  }

  isTrailer = ()=> {
    if (this.props.trailerLinkMobile) {
      return true
    }
    return this.props.trailerLinkWeb ? true : false      
  }

  startVideo = (video) => {
    this.playerInit = setTimeout(() => {
      const JP = window.jwplayer;
      const uri = video?.currentSrc || video?.children[0]?.src;
      const isValid = navigator?.onLine && isMobileOrTab() === false;
      if(uri && isValid){
        if(this.isM3u8(uri) && JP ){
          if(JP(video.id).setup)
          {
            this.setState({showVideo : true})
            this.player = JP(video.id).setup({
              file:uri,
              image:video.poster,
              controls:false,
              repeat: true,
              events:{
                "ready":()=>{
                  this.handelRightClick(this.player);
                  if(this.player.wexer) this.player.__proto__.wexer = undefined  // clearing custom data from jw player
                  const ele = this.player.getContainer();
                  const workoutPlayerEle =  ele.closest(".workoutPlayer");
                  const cotd2Ele =  ele.closest(".cotd2");
                  if(workoutPlayerEle){
                    workoutPlayerEle.classList.add("h-218")
                  }
                  if(cotd2Ele){
                    cotd2Ele.classList.add("w-256")
                  }
                  ele.classList.add("Trailer","thumbnail","video-js");
                },
                "click":() => {
                  this.props.clicked()
                },
                "remove":function(){
                  const parentDiv = document.getElementById(video.id).parentNode
                  if(this.playerInit !== null){
                    parentDiv.id.includes('cotd-card') && parentDiv.classList.add('cotd-overlay')
                    clearTimeout(this.playerInit);
                    this.playerInit=null;
                  }
                },
                "time":()=>{
                  // check video length ? 6 mins => start from 5 mins else start from 0 mins , play duration 30 secs
                  if(!this.isTrailer() && this.player){
                  const videoLength = this.player.getDuration()
                  const currentDuration = this.player.getCurrentTime()
                  //check for video's duration > 6 mins
                  if (videoLength > 360){
                    if(330>currentDuration > 0 && this.player.wexer){ //check current playing time, avoid re-rendering
                      this.player.__proto__.wexer = {...this.player.wexer,seek : false};
                    } else{
                      this.player.__proto__.wexer = {...this.player.wexer,seek : true};
                    }
                    if( !this.player?.wexer?.trailer && this.player?.wexer?.seek===true ) this.player.seek(300); //check jw Player data,start video from 5mins
                    this.player.__proto__.wexer = {...this.player.wexer,trailer : true};
                    if (currentDuration > 330) { //check current playing time > 5mins 30 sec, reset the trailer
                    this.player.__proto__.wexer = {...this.player.wexer,trailer : false};
                    }
                  }
                   else if(currentDuration > 30){
                    this.player.seek(0);
                  }
                }
              },
              "beforePlay":function(){
                const currentPlayer = this.getContainer();
                const videos = document.querySelectorAll("div.jwplayer")
                videos.forEach((video)=>{
                if(video.id !== currentPlayer.id) JP(video.id).remove()
                 })
              },
                "play":function(){
                  const ele = this.getContainer();
                  const parentDiv = ele.parentNode
                  parentDiv.id.includes('cotd-card') && parentDiv.classList.remove("cotd-overlay")
                  ele.addEventListener("mouseleave",()=>{
                    // ele.querySelector("video").load();
                    this.remove();
                  })
                },
                "error":function(){
                  try {
                    const ele = this.getContainer();
                    const videoErrEle = ele.querySelectorAll(".jw-error-msg");
                    if(ele){
                        ele.querySelector("video").load();
                        this.remove();
                        videoErrEle[0].remove()
                    }
                    } catch (error) {
                      console.log(error.name)
                      console.log(error.message)
                    }
                }
              }
            });
          }
        }
        else{
              var playPromise = video.play();
              this.setState({showVideo : true})
              if (playPromise !== undefined) {
                const parentDiv = video.parentNode
                parentDiv.id.includes('cotd-card') && parentDiv.classList.remove("cotd-overlay")
                playPromise.then(_ => {
                  // Automatic playback started!
                  // Show playing UI.
                  logger("video started")
                })
                  .catch(error => {
                    // Auto-play was prevented
                    // Show paused UI.
                    logger("video Downloading")
                  });
              }
        }
      }
    }, 1000);
  }
  favVideo = () => {
    if (this.props.favCallback) {
      this.props.favCallback();
    }
    logger(this.props.collectionName)
    var userId = localStorage.getItem("userId");
    logger("id", this.props.id)
    this.props.onsetClassFavouriteLocal(this.props.collectionName, this.props.id, true)
    this.props.onsetClassFavourite(this.props.id, userId, true);
    this.setState({ isClassFav: true });
  };
  unfavVideo = () => {
    if (this.props.favCallback) {
      this.props.favCallback();
    }
    this.setState({ isClassFav: false });
    var userId = localStorage.getItem("userId");
    this.props.onsetClassFavouriteLocal(this.props.collectionName, this.props.id, false)
    this.props.onsetClassFavourite(this.props.id, userId, false);
  };

  isNewCardFn = (creationDate) => {
    let date = parse(creationDate);
    let difference = differenceInCalendarDays(new Date(), date);
    if (difference < config.numberOfDaysToShowNewOnWorkoutCard) {
      this.setState({
        isClassNew: true
      })
    }
  }

  isM3u8 =(str) =>{
    try {
      return str.match(/m3u8/ig)
    } catch (error) {
      return false;
    }
  }
  getMimeType(uri){
    try {
    return this.isM3u8(uri) ? "application/x-mpegURL" :  "video/mp4";
    } catch (error) {
      console.log("getMimeType",error.message,uri,error.name)
      return "video/mp4"
    }
  }
   cotdDate = (date)=>{
    try{
    return moment(date).format("dddd DD MMMM")
    } catch(e){
    return moment().format("dddd DD MMMM")
    }
 }

 getTrailerURL = () => {  
    if (this.props.trailerLinkMobile) {
      return this.props.trailerLinkMobile
    }
    if (this.props.trailerLinkWeb) {
      return this.props.trailerLinkWeb
    }
    return null
 }
 
handleClick = () => {
  let token = localStorage.getItem("token")
  if (!token){
    this.props.navigate(Routes.signinPage)
  } else{
    if (this.props.isUnlocked === true) {
      this.props.clicked();
    } 
    else if (this.props.isUnlocked === false ){
      const lockedBehaviour = config.lockedContentBehaviour
      switch (lockedBehaviour) {
        case TenantLockedContentBehaviour.show_popup:
          this.setState({ showDialog: true });
          break;
        case TenantLockedContentBehaviour.show_subscription_options:
          this.props.navigate(Routes.subscribeRoute)
          break;
        case TenantLockedContentBehaviour.do_nothing:
          //do nothing
          break;  
          default:
          //do nothing
          break;        
      }
    }
  }}

  showLockIcon = () => {
    if (
      this.props.isUnlocked === true
    ) {
      return false;
    }
    return true;
  };

  okButtonClick = () => {
    this.setState({ showDialog: false });
    if (config.UpgradeRedirect != null) {
      window.open(config.UpgradeRedirect, "_blank");
    } 
  };


  render() {
    const uri = this.getTrailerURL()
    const cardType = this.props.cardType
    const collectionType = this.props.collectionType
    const metadataEntitlement = this.props.metadata
    const section = this.props.section
    
    return (
        <div key={this.props.id}>
        {this.state.showDialog ? (
          <CustomDialog
            open={true}
            title={Translate({ id: "Subscription.UpgradeMembership" })}
            heading={Translate({ id: "Subscription.UpgradeMessage" })}
            aria-label='Subscription'
          >
            <div className="text-center padding16">
              <Button
                size="small"
                type="submit"
                color="secondary"
                className="button btn-default"
                variant="contained"
                onClick={this.okButtonClick}
                style={{ marginTop: "60px" }}
                role='button'
                aria-label='Subscription'
                tabIndex='0'
              >
                <Typography variant="h5" tabIndex='0' role='button'  aria-label='Subscription'>
                  {Translate({ id: "Subscription.Ok" })}
                </Typography>
              </Button>
            </div>
          </CustomDialog>
        ) : null}
        {collectionType !== "channel" && <div><div className={`${cardType} makeTextClickable`} onClick={this.handleClick} aria-label="Video Button" tabIndex="0" role='button'
                    onMouseOut={e => {
                      if(this.playerInit !== null){
                      this.setState({showVideo : false})
                      clearTimeout(this.playerInit);
                      this.playerInit=null;
                      e.target.load();
                      if(this.player){
                        this.player.remove()
                      }
                    }
                  }}
                    > <div className={`cardPos comman`} > 
                          <div  role="heading" aria-level="2" id={cardType+this.props.id} className={`imageHolder ${cardType !== 'cotd-card' ? collectionType !== 'featured' ? "full-overlay":"noOverlay" :"cotd-overlay"}`}>
                            <video
                                style={{
                                  backgroundColor: this.state.isPlaying ? "#000" : "transparent"
                                }}
                                id = {`video-${this.props.id}`}
                                preload="none"
                                loop
                                playsInline
                                ref={this.myRef}
                                onMouseOver={(e) => {
                                  !this.props.isMoreClassSuggetion && this.startVideo(e.target)
                                }}
                                className={`Trailer thumbnail`}
                                poster={this.props.thumbnail ?
                                    appendQueryParameterInURL(this.props.thumbnail,"width",getPosterSize(cardType).cardWidth) : ""
                                  }
                                onMouseOut={e => {
                                  if(this.playerInit !== null){
                                    const parentDiv = document.getElementById(`video-${this.props.id}`).parentNode
                                    parentDiv.id.includes('cotd-card') && parentDiv.classList.add('cotd-overlay')
                                    clearTimeout(this.playerInit);
                                    this.setState({showVideo : false})
                                    this.playerInit=null;
                                    e.target.load();
                                    if(this.player){
                                      this.player.remove()
                                    }
                                }
                              }}
                             
                              data-testid={this.props.title}
                              >
                                <source
                                  src={uri}
                                  type={this.getMimeType(uri)}
                                />
                            </video>
                            {!this.state.showVideo && <div className="contentTop">
                                <div className="timeholder">
                               {collectionType ==="live" && <span  className="live-title m-b-8">{this.props?.title?.substring(0,20)}</span> }
                               {collectionType !=="featured" && <span  className={`classname ${cardType ===  'cotd-card' ? "cotd-date":"m-b-5 "}`}>{this.cotdDate(this.props.displayDate)}</span> }
                                </div>
                            </div>}
                          </div>
                      </div>
                    </div>
                {collectionType !=="live" && 
                <div className="card-footer">
                   <div className="d-flex"> 
                   <div  role="heading" aria-level="2" style={{color:'black'}} className={cardType === 'cotd-card' ? "time title-truncate cotd-time":"time title-truncate"}>{(this.props.title)}
                   </div></div>
              <div className="d-flex card-footer-info">
                {section === "playlist" ? <span color="white" style={{color:'#757579'}} spacing="xl" className="font-caption">
                  {this.props.totalClasses && Translate(this.props.totalClasses === 1 ? 
                  {id: "Playlist.PlaylistClasses_one",
                    values: { count: this.props.totalClasses },
                    defaultMessage: "{count} class",
                  }: {
                      id: "Playlist.PlaylistClasses_other",
                      values: { count: this.props.totalClasses },
                      defaultMessage: "{count} classes",
                    })}</span> :(
                            <>
                              <span  style={{color:'#757579'}} spacing="xl" className="font-caption">
                              {this.props.duration !== 0 ? `${this.props.duration}` : `1`} {" "}
                              {this.props.duration > 1 ?Translate({ id: "Player.Mins" }):Translate({ id: "Player.min" })}</span>
                              {collectionType ==="featured" &&  <span color="white" spacing="s" style={{color:'#757579'}} className="font-caption discIcon"> {getCardsDate(this.props.displayDate)}</span>}
                              {metadataEntitlement?.skills && this.props.level != undefined && <span color="white" spacing="s" style={{ color: '#757579' }} className="font-caption discIcon">{Translate({ id: `filter.${SkillLevelText[this.props.level]}` })}</span>}
                            </>
                            )}
                         
                         {this.showLockIcon() && (
                           <LockIcon color={"#737777"} classes={"footer-lock-icon"} />
                         )}
                        </div>
                        <div className="d-flex">
                {cardType !== 'cotd-card' && this.state.isClassNew && <NewButton message={"ClassListViews.new"} cls={"new-bubble"} />}
                {this.props.mediaType === ClassMediaType.Audio && <NewButton message={"ClassListViews.AudioWorkout"} cls={"audio-bubble"} />}
                        </div>
                        </div>} 
                </div>}

      {this.props.channelDescription && <div className={` ${cardType} makeTextClickable`} >
        <div className={`cardPos comman`} onClick={this.props.clicked} role='button' aria-label="channel-descriptions" tabIndex="0">
          <div  data-testid={"className"} className="imageHolder full-dark-overlay" style={{backgroundImage:`url(${appendQueryParameterInURL(this.props.thumbnail,"width",getPosterSize(cardType).cardWidth)})`}}  >
            <div className="class-card__CardContentTop contentTop">
              <div className="timeholder">
                 <span  className="channelname single-truncate">{this.props.displayName}</span>
              </div>
            </div>

            <div className="class-card__CardContentBottom-sc-8519zh-3 bottomHolder ">
             <img src={this.props.profileImageUrl} className="channelImg" alt="channelImage" />
             <span  spacing="s" className="font-caption truncate"> { this.props.channelDescription}</span>
            </div>
          </div>
        </div>
      </div>}
    </div>
    );
  }
}

const Workout = (props) => {
  const navigate = useNavigate();

  return <WorkoutComponent navigate={navigate} {...props}/>;
};

export default (withStyles(style)(Workout))