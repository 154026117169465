import React from 'react'
import { IconButton, MenuItem, Menu, Typography } from '@material-ui/core';
import { Translate } from "../utils/Translate";
import { useSelector } from "react-redux"
import { useNavigate } from 'react-router';
import { Close } from '@material-ui/icons';
import { SearchItems } from './SearchItems';
import Routes from './Router/Routes'
import MoreVertical from '../CommonImages/more-vert';
const config = require("../assets/config.json")


const ITEM_HEIGHT = 48;
const AccountButtonDotsMobile = (props) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const isAuthenticated = useSelector(state => state.auth.token !== "");
    const entitlement = props.entitlement
    const featuresConfig = props.featuresConfig
    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handelBack =()=>{
        const inputEle = document.querySelector(".comp-search .text-box input");
        if(inputEle?.value?.length){
            props.handelBack();
            handleClose();
        }
    }
    return (

        !isAuthenticated ? (
            <div>
                {/* <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className="kebab"
                >
                    <MoreVertical />

                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: 200,
                        },
                    }}
                    style={{ top: "25px", left: "-10px" }}
                >
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            props.history.push({ pathname: "/signup" });
                        }}
                    >
                        <Typography variant="button">{Translate({ id: "layout.SignUp" })}</Typography>
                    </MenuItem>

                </Menu> */}
            </div>
        ) : (<div>
            <IconButton
                aria-label="long-menu"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="kebab"
                tabIndex="0"
            >
                {anchorEl!==null ? <Close className='icon-close' width={30} height={30} color={config.primaryColor}/> : 
                <MoreVertical color={config.primaryColor}/>}
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                //keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: 210,
                    },
                }}
                style={{ top: "40px", left: "-10px" }}
                role='button'
                aria-label='Menu'
                tabIndex='0'
            >
                {!props.isSearchPage &&
                <MenuItem className='search-items'>
                    <SearchItems
                        handelBack={handelBack}
                        loading={props.loading}
                        noCrossIcon
                        role='button'
                        aria-label='Search'
                        tabIndex='0'
                    />
                </MenuItem>}
                    {(entitlement.live || entitlement.ondemand) &&
                    <MenuItem
                        onClick={() => {
                            setAnchorEl(null);
                            entitlement.live ?
                                navigate( Routes.connectLiveSchedule ) :
                                navigate( Routes.connectOndemandClasses )
                        }}
                    role='button' aria-label='Live or Ondemand page'  tabIndex='0'>
                        <Typography variant="button" role='button'  tabIndex='0' aria-label='Search'>{Translate({ id: "navigation.ConnectTopNav" })}</Typography>
                    </MenuItem>}
                {entitlement.categories && <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        navigate( Routes.classListViewRoute );
                    }}
                role='button'  tabIndex='0'>
                    <Typography variant="button" aria-label='Class page' role='button'  tabIndex='0'>{Translate({ id: "navigation.ClassesTopNav" })}</Typography>
                </MenuItem>}
                {entitlement.channels && <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        navigate( "/channels/" );
                    }}
                aria-label='Channels' 
                role='button' tabIndex='0'>
                    <Typography variant="button" role='button' aria-label='Channels' tabIndex='0'>{Translate({ id: "navigation.ChannelsTopNav" })}</Typography>
                </MenuItem>}
                {entitlement.collection && <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        navigate( Routes.collections );
                    }}
                role='button' tabIndex='0' aria-label='Collections' >
                    <Typography variant="button" role='button' aria-label='Collections' tabIndex='0'>{Translate({ id: "navigation.CollectionsTopNav" })}</Typography>
                </MenuItem>}
                {featuresConfig?.subscription && entitlement.subscription && <MenuItem
                    onClick={() => {
                        setAnchorEl(null);
                        navigate(Routes.mySubscriptionsRoute );
                    }}
                role='button' tabIndex='0' aria-label='Subscription page'>
                    <Typography variant="button" tabIndex='0' role='button' aria-label='Subscription page'>{Translate({ id: "navigation.Subscriptions", defaultMessage: "Subscriptions" })}</Typography>
                </MenuItem>}
            </Menu>
        </div>)
    )
}

export default AccountButtonDotsMobile;
