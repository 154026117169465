import React from "react";
import {Typography, Grid} from "@material-ui/core";
import {PropTypes} from "prop-types";
import {Translate} from "utils/Translate";
import {getImage} from "utils/utilityfunctions";
import InnerBannerComponent from "./InnerBannerComponent";

function BannerComponent(props) {
  const handleClick = () => {
    props.handleClick()
  }
  return (
    <>
      <div className="">
        <Grid container id="channelBg">
          <div className="fixedWidthContainer container channelbg-banner">
            {
              <div className="bg-placeholder transition">
                <div className="fallbackBox" />
                <img
                  src={props.imageUrl}
                  className="bg-banner"
                  alt="bannerImage"
                  tabIndex="0"
                  data-testid={props.imageUrl ? "banner-image-id" : ""}
                />
              </div>
            }
            <div className="overlay banner-overlay">
              <div style={{ zIndex: 1 }} className="page-heading text-white">
                {props.topHeading && props.heading && (
                  <div
                    className={`paddingTopHeader font-h6 ${props.topHeadingClass}`}
                    onClick={handleClick}
                    role="button"
                    aria-label="Homepage"
                    tabindex="0"
                  >
                    {props.topHeading}
                  </div>
                )}
                <div className="channelbgimg"  role="button" aria-label="channelbgimg" tabindex="0">
                  {props?.titleImage && (
                    <img
                      src={props.titleImage}
                      className="channel-profileImg"
                      alt="profile"
                      tabIndex="0"
                    />
                  )}

                  {props?.heading && (
                    <Typography
                      variant="h1"
                      className="m-t-xs-5 "
                      data-testid="banner-heading-id"
                      tabindex="0"
                    >
                      {props.heading}
                    </Typography>
                  )}
                </div>
                <Grid container direction="row">
                  <Grid
                    item
                    lg={12}
                    md={12}
                    className="channelDescription hidden-xs banner-desc-clamp"
                  >
                    {props?.subHeading && (
                      <h2
                        className="padding16 font-h6"
                        data-testid="banner-sub-heading-id"
                      >
                        {props.subHeading}
                      </h2>
                    )}
                  </Grid>
                </Grid>
              </div>
              <div className="inner-banner-component">{props.rightChild}</div>
            </div>
          </div>
        </Grid>
        <div className="pseudo-div"></div>
      </div>
    </>
  );
}
BannerComponent.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
};

export default BannerComponent;
