/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { Fragment, useState } from 'react'
import { Grid, Typography, Chip, Button, Paper } from "@material-ui/core";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import FilterDrawer from '../../LiveConnect/filterDrawer';
import { Translate } from '../../../utils/Translate';
import { useFilterKeywords } from "../../../hooks/useFilterKeywords"
import IconFilter from "../../../CommonImages/iconFilter.js"
import IconFilterBlack from "../../../CommonImages/IconFilterBlack.js"

const FilterKeywords = (props) => {
   const { useFilterKeywordsState, useFilterKeywordsDispatch } = useFilterKeywords();
   const { selectedFilters, filteredKeywords, filterSearchText, loading } = useFilterKeywordsState()
   const { setOnDemandKeywords } = useFilterKeywordsDispatch();

  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const keywordSelected = (keyword) => {
    props.fetchDataByTopKeywords(keyword,undefined,true)
    window.scrollTo(0, 0);
  }

  const filterImage = () => {
    if (selectedFilters.length > 0 || filterSearchText.length > 0 ){
      return <IconFilter />
    }
    else{
      return <IconFilterBlack />
    } 
  }

  return (
    !loading && <Fragment>
      <Grid item lg={12} md={12} sm={12} xs={12} className="align-left height-fix hidden-xs m-t-xs-15 m-b-5">
        {props.topKeywords && props.topKeywords.length > 0 &&  <Typography className=" text-gray" variant="caption" >
            {Translate({ id: "ClassListViews.TopKeywords" })}
          </Typography>}
        </Grid>
      <Grid item lg={9} md={9} sm={10} xs={10} className="mobileScroll">
        {props.topKeywords && props.topKeywords.length > 0 &&
          <Paper component="ul" className="chipUl flexNoWrap">
            {props.topKeywords.map((key, index) => (
              index < 3 && <li key={Math.random()}>
                <Chip label={Translate({ id: `keywords.${key}` })} className={`makeTextClickable ${key === filteredKeywords ? "bg-green text-white" : ""}`} onClick={() => { keywordSelected(key) }} role='button' aria-label='Keywords' tabIndex='0'>
                </Chip>
              </li>
            ))}
          </Paper>}
      </Grid>
      <Grid item lg={3} md={3} sm={2} xs={2} className="text-right" >
        {(selectedFilters.length > 0 || filterSearchText.length > 0) && <a className="dynamiclinks linkFontSize makeTextClickable align-middle hidden-xs hidden-md" onClick={() => {
          setOnDemandKeywords('');
          // dispatch(setKeywordsOnDemand(''));
          props.fetchDataByFilters([], false, true)
        }} role='button' aria-label='Reset' tabIndex='0'>
          {Translate({ id: "filter.Reset" })}
        </a>}
        <Button variant="contained"
          // className="view-btn filter-btn m-l-16"
          className={`${(selectedFilters.length > 0 || filterSearchText.length > 0) ? "view-btn filter-btn m-l-16 btn-default" : "view-btn filter-btn m-l-16"}`}
          color={`${(selectedFilters.length > 0 || filterSearchText.length > 0) ? "secondary" : "inherit"}`}
          onClick={handleDrawerOpen}
          role='button'
          aria-label='Filter'
          tabIndex='0'
        >
          <Typography variant="h2" className="hidden-xs MuiTypography-h4" aria-label='Filter' tabIndex='0'>
            {Translate({ id: "ClassListViews.Filter" })}
            {/* <img src={`${selectedFilters.length > 0 || filterSearchText.length > 0 ? require("../../../assets/images/icon-filter.svg") : require("../../../assets/images/icon-filter-black.svg")}`} alt='' /> */}
          </Typography>
          {filterImage()}
        </Button>
        <SwipeableDrawer
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
        >
          <FilterDrawer 
            showFLLOnDemand={true}
            closeHandler={handleDrawerClose}
            openHandler={handleDrawerOpen}
            keywords={props.topKeywords}
            selected={keywordSelected}
            initialFilterMetaData={props.initialFilterMetaData}
            fetchDataByFilters={props.fetchDataByFilters}
            classesCount={props.classesCount}
            categoryName={props.categoryName}
            fetchDataByFilterSearch={props.fetchDataByFilterSearch}
          />
        </SwipeableDrawer>
      </Grid>
    </Fragment>
  )
}
export default FilterKeywords;

